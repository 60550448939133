<template>
  
</template>

<script>
export default {
    created(){
        localStorage.removeItem("teacher")
        setTimeout(() => {
            window.location = '/_xteacher/login'
        }, 1000);
    }
}
</script>

<style>

</style>